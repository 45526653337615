import { convertKeysToLowerCase } from '@/lib/utils/objects';
import { RateProviders } from '../types';

const rateProviders: RateProviders = {
  '*': {
    '0x0000000000000000000000000000000000000000': true,
  },
  '0x5979d7b546e38e414f7e9822514be443a4800529': {
    '0xf7c5c26b574063e7b098ed74fad6779e65e3f836': true,
  },
  '0x7f39c581f595b53c5cb19bd0b3f8da6c935e2ca0': {
    '0xf7c5c26b574063e7b098ed74fad6779e65e3f836': true,
  },
  '0xb86fb1047a955c0186c77ff6263819b37b32440d': {
    '0x2ba447d4b823338435057571bf70907f8224bb47': true,
  },
};

export default convertKeysToLowerCase(rateProviders);
