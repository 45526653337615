import { TokenListURLMap } from '@/types/TokenList';

// TODO
const tokenlists: TokenListURLMap = {
  Balancer: {
    Allowlisted:
      'https://raw.githubusercontent.com/fabianschu/tokenlists/main/generated/balancer.tokenlist.json',
  },
  External: [],
};

export default tokenlists;
